import { SavedLesson } from '@customtypes/parsedLesson';
import { User } from 'next-firebase-auth';
import { getBaseUrl } from '@utils/general-utils';
import { userToken } from '@utils/firebase/initAuth';
import { fetcherAndLog } from '@utils/fetcher';
import { ChapterWithContent } from '@customtypes/chapter';

export async function fetchPreviewChapter(
  trackId: number,
  tutorialId: number,
  chapterId: number,
  user: User,
): Promise<ChapterWithContent> {
  const token = await userToken(user);
  const result = await fetcherAndLog(
    `${getBaseUrl(
      true,
    )}/tracks/${trackId}/tutorials/${tutorialId}/chapters/${chapterId}`,
    { method: 'GET', headers: { Authorization: `Bearer ${token}` } },
  );

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not fetch chapter - ${result.statusText} (${result.status})${
        message ? `: ${message}` : ''
      }`,
    );
  }
  return await result.json();
}

export interface SavedFilesResponse {
  savedFiles: SavedLesson[];
}

export async function savedFiles(
  trackId: number,
  courseId: number,
  chapterId: number,
  trackVersion: number,
  user: User,
): Promise<SavedFilesResponse> {
  const token = await userToken(user);
  const result = await fetcherAndLog(
    `${getBaseUrl(
      false,
    )}/tracks/${trackId}/tutorials/${courseId}/chapters/${chapterId}/savedFiles?trackVersion=${trackVersion}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
  );

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not fetch saved files for lesson - ${result.statusText} (${
        result.status
      })${message ? `: ${message}` : ''}`,
    );
  }
  return await result.json();
}

export async function clearSavedFiles(
  trackId: number,
  courseId: number,
  chapterId: number,
  trackVersion: number,
  user: User,
): Promise<void> {
  const token = await userToken(user);
  const result = await fetcherAndLog(
    `${getBaseUrl(
      false,
    )}/tracks/${trackId}/tutorials/${courseId}/chapters/${chapterId}/savedFiles/clear?trackVersion=${trackVersion}`,
    { method: 'POST', headers: { Authorization: `Bearer ${token}` } },
  );
  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not fetch saved files for lesson - ${result.statusText} (${
        result.status
      })${message ? `: ${message}` : ''}`,
    );
  }
}

export async function postSavedFiles(
  trackId: number,
  courseId: number,
  chapterId: number,
  lessonId: number,
  publishSetVersion: number,
  files: any[],
  user: User,
) {
  const token = await userToken(user);
  const url = `${getBaseUrl(
    false,
  )}/tracks/${trackId}/tutorials/${courseId}/chapters/${chapterId}/lessons/${lessonId}/savefiles?publishSetVersion=${publishSetVersion}`;
  const body = {
    files: files.map((file) => ({
      userContent: file.content,
      name: file.name,
    })),
  };
  const result = await fetcherAndLog(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not post saved files - ${result.statusText} (${result.status})${
        message ? `: ${message}` : ''
      }`,
    );
  }
  return result;
}

export async function postSavedFilesToPreview({
  trackId,
  tutorialId,
  chapterId,
  lessonId,
  user,
  files,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  user: User;
  files: any[];
}) {
  const token = await userToken(user);
  const url = `${getBaseUrl(
    true,
  )}/tracks/${trackId}/tutorials/${tutorialId}/chapters/${chapterId}/lessons/${lessonId}/runfiles`;
  const body = {
    files: files.map((file) => ({
      userContent: file.content,
      name: file.name,
    })),
  };
  const result = await fetcherAndLog(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not post saved files to preview - ${result.statusText} (${
        result.status
      })${message ? `: ${message}` : ''}`,
    );
  }
  return result;
}

export async function getSavedFilesFromPreview({
  trackId,
  tutorialId,
  chapterId,
  user,
}: {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  user: User;
}): Promise<SavedFilesResponse> {
  const token = await userToken(user);
  const url = `${getBaseUrl(
    true,
  )}/tracks/${trackId}/tutorials/${tutorialId}/chapters/${chapterId}/savedFiles`;
  const result = await fetcherAndLog(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });

  if (result.status === 404) {
    throw new Error('no files found');
  }
  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not fetch saved files for lesson - ${result.statusText} (${
        result.status
      })${message ? `: ${message}` : ''}`,
    );
  }

  return await result.json();
}

export async function clearSavedFilesFromPreview(
  trackId: number,
  tutorialId: number,
  chapterId: number,
  user: User,
) {
  const token = await userToken(user);
  const url = `${getBaseUrl(
    true,
  )}/tracks/${trackId}/tutorials/${tutorialId}/chapters/${chapterId}/savedFiles/clearCache`;
  const result = await fetcherAndLog(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  });

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `Could not clear saved files cache - ${result.statusText} (${
        result.status
      })${message ? `: ${message}` : ''}`,
    );
  }
  return result;
}
