import introChatStateMachine from '@machines/introChatStateMachine';
import { TutorialType } from './tutorial';
import { CodingLanguage } from './parsedLesson';
import { TrackId } from '@utils/constants';
import { ONBOARDING_STEPS_QUERY_PARAM } from '@machines/onboardingExistingUser.machine';
import { StateValueFrom } from 'xstate';

type LessonType =
  | 'lesson'
  | 'fill_the_gap'
  | 'executable_code'
  | 'multiple_choice'
  | 'order_the_lines'
  | 'validated_input';

// open_lesson
export interface OpenLessonOptions {
  track_id: number;
  tutorial_id: number;
  chapter_id: number;
  lesson_id: number;
  chapter_index: number;
  section_index: number;
  tutorial_version: number;
  lesson_type: LessonType;
  tutorial_type: TutorialType;
}

export function trackOpenLesson(mixpanel: any, options: OpenLessonOptions) {
  mixpanel?.track('open_lesson', options);
}

// show_solution
export interface ShowSolutionOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
  attempts?: number;
}

export function trackShowSolution(mixpanel: any, options: ShowSolutionOptions) {
  mixpanel?.track('show_solution', options);
}

// apply_solution
export interface ApplySolutionOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
  attempts?: number;
}

export function trackApplySolution(
  mixpanel: any,
  options: ApplySolutionOptions,
) {
  mixpanel?.track('apply_solution', options);
}

// hide_solution
export interface HideSolutionOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
  attempts?: number;
}

export function trackHideSolution(mixpanel: any, options: HideSolutionOptions) {
  mixpanel?.track('hide_solution', options);
}

// open_comparison_dialog
export interface OpenComparisonDialogOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
}

export function trackOpenComparisonDialog(
  mixpanel: any,
  options: OpenComparisonDialogOptions,
) {
  mixpanel?.track('open_comparison_dialog', options);
}

// edit_solution
export interface EditSolutionOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
}

export function trackEditSolution(mixpanel: any, options: EditSolutionOptions) {
  mixpanel?.track('edit_solution', options);
}

// submit_code
export interface SubmitCodeOptions {
  isCorrect: boolean;
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
  attempts: number;
}

export function trackSubmitCode(mixpanel: any, options: SubmitCodeOptions) {
  mixpanel?.track('submit_code', options);
}

// run_code
export interface RunCodeOptions {
  trackId: number;
  tutorialId: number;
  chapterId: number;
  lessonId: number;
  tutorialVersion: number;
  lessonType: LessonType;
  tutorialType: TutorialType;
  attempts?: number;
}

export function trackRunCode(mixpanel: any, options: RunCodeOptions) {
  mixpanel?.track('run_code', options);
}

// pageview
export interface PageViewOptions {
  navigatedTo: string;
  navigatedFrom?: string;
}

export function trackPageView(mixpanel: any, options: PageViewOptions) {
  mixpanel?.track('pageview', options);
}

// finish_chapter
export interface FinishChapterOptions {
  track_id: number;
  tutorial_id: number;
  chapter_id: number;
  section_index: number | undefined;
  chapter_index: number | undefined;
  tutorial_version: number;
  tutorial_type: TutorialType;
}

export function trackFinishChapter(
  mixpanel: any,
  options: FinishChapterOptions,
) {
  trackCustomerIOEvent('finish_chapter', options);
  mixpanel?.track('finish_chapter', options);
}

export interface UnlockTutorialOptions {
  tutorial_id: number;
  tutorial_title: string;
  tutorial_index: number;
}

export function trackUnlockTutorial(
  mixpanel: any,
  options: UnlockTutorialOptions,
) {
  mixpanel?.track('unlock_tutorial', options);
}

// finish_lesson
export type FtgInputType = 'keyboard' | 'mouse' | 'keyboard_and_mouse';
export interface FinishLessonOptions {
  track_id: number;
  tutorial_id: number;
  chapter_id: number;
  lesson_id: number;
  section_index: number | undefined;
  chapter_index: number | undefined;
  tutorial_version: number;
  attempts: number | undefined;
  used_solution: boolean | undefined;
  duration: number;
  tutorial_type: TutorialType;
  ftg_input_type?: FtgInputType;
}

export function trackFinishLesson(mixpanel: any, options: FinishLessonOptions) {
  mixpanel?.track('finish_lesson', options);
}

export interface SkipLessonOptions {
  track_id: number;
  tutorial_id: number;
  chapter_id: number;
  lesson_id: number;
  tutorial_version: number;
  attempts: number | undefined;
  used_solution: boolean | undefined;
  duration: number;
}

export function trackSkipLesson(mixpanel: any, options: SkipLessonOptions) {
  mixpanel?.track('skip_lesson', options);
}

export function trackSuccessfulSignin(
  mixpanel: any,
  options: { source: 'email' | 'facebook' | 'google' | 'apple' },
) {
  mixpanel?.track('login', options);
}

export function trackSuccessfulSignup(
  mixpanel: any,
  options: {
    source: 'email' | 'google' | 'apple';
  },
) {
  mixpanel?.track('signup', options);
}

export type SubscribePageTrackingReferer =
  | 'pricing_modal'
  | 'web_onboarding'
  | 'web_onboarding_existing_users'
  | 'web_navbar'
  | 'web_max_card'
  | 'web_fix_in_chat'
  | 'web_projects_page'
  | 'web_project_ai_chat_upgrade_card'
  | 'web_customise_project'
  | 'web_community_dropdown'
  | 'web_first_tutorial_finished_flow'
  | 'web_web2app_flow'
  | 'web_quick_action'
  | 'web_first_tutorial_finished_flow';

// track show upgrade modal
export function trackShowUpgrade(
  mixpanel: any,
  options: {
    source: SubscribePageTrackingReferer;
  },
) {
  mixpanel?.track('show_upgrades', options);
}

// track show upgrade modal
export function trackContinueWithCurrentSubscription(
  mixpanel: any,
  options: {
    currentSubscription: 'free' | 'pro' | 'max';
  },
) {
  mixpanel?.track('continue_with_current_subscription', options);
}

export function trackUpgradeLink(
  mixpanel: any,
  elementId: string,
  options: {
    interval: 'monthly' | 'yearly';
    price: string;
    subscription_type: 'pro' | 'pro+';
  },
) {
  mixpanel?.track_links(`#${elementId}`, 'click_to_upgrade', options);
}

export function trackSelectedTrackDuringOnboarding(
  mixpanel: any,
  options: { trackId: number },
) {
  mixpanel?.track('selected_track_during_onboarding', options);
}

// track copy to clipboard
export function trackClickToCopy(mixpanel: any, options: { type: string }) {
  mixpanel?.track('click_to_copy', options);
}

// finish_tutorial
export interface FinishTutorialOptions {
  tutorial_type: TutorialType;
  tutorial_id: number;
  section_index: number;
  track_id: number;
}

export function trackFinishTutorial(
  mixpanel: any,
  options: FinishTutorialOptions,
) {
  mixpanel?.track('finish_tutorial', options);
}

//streaks
export function trackOpenStreaksDropdown(
  mixpanel: any,
  options: { streak_day: number },
) {
  mixpanel?.track('open_streaks_dropdown', options);
}

//coins
export function trackOpenCoinsDropdown(mixpanel: any) {
  mixpanel?.track('open_coins_dropdown');
}

//community
export function trackOpenCommunityDropdown(mixpanel: any) {
  mixpanel?.track('open_community_dropdown');
}

export function trackStreakCalendarNavigation(
  mixpanel: any,
  options: { month: number },
) {
  mixpanel?.track('calendar_scrolled', options);
}

export type PageSource =
  | 'guided_project'
  | 'freeform_project'
  | 'customized_guided_project'
  | 'learn'
  | 'not_set';

export function trackTutorInteraction(
  mixpanel: any,
  options: {
    prompt:
      | 'custom'
      | 'give_me_a_hint'
      | 'ask_in_chat'
      | 'explain_task'
      | 'code_editor_overlay_fix_in_chat'
      | 'terminal_fix_in_chat'
      | 'console_fix_in_chat'
      | 'learn_chapter_ask_ai'
      | 'quick_action_explain_code'
      | 'quick_action_explain_instruction';
    source: PageSource;
    lessonId?: number;
    chapterId?: number;
    taskIndex?: number;
  },
) {
  mixpanel?.track('tutor_interaction', options);
}

export function trackCopyContentToChat(
  mixpanel: any,
  options: {
    type: 'code' | 'instructions';
    source: PageSource;
  },
) {
  mixpanel?.track('copy_content_to_chat', options);
}

export function trackTutorChatboxCollapsedState(
  mixpanel: any,
  options: {
    toState: 'collapsed' | 'expanded';
  },
) {
  mixpanel?.track('toggle_chatbox', options);
}

export function trackTutorChatboxShowAdditionalInfo(
  mixpanel: any,
  options: {
    toState: 'collapsed' | 'expanded';
  },
) {
  mixpanel?.track('toggle_additional_info', options);
}

export function trackTutorChatboxEditorFeedbackShown(
  mixpanel: any,
  options: {
    type: 'correct' | 'wrong';
    content?: string;
  },
) {
  mixpanel?.track('editor_feedback_component_shown', options);
}

// track apply outbound link

interface TrackApplyMimoDevOptions {
  source: string;
}

export function trackOutboundApplyMimoDev(
  mixpanel: any,
  elementId: string,
  options: TrackApplyMimoDevOptions,
) {
  mixpanel?.track_links(`#${elementId}`, 'clicked_join_full_program', options);
}

// track "start free trial" outbound link

interface TrackUpgradeOptions {
  source: 'free_trial';
  upgrade_type: 'trial';
}

export function trackOutboundUpgradeLinkClicked(
  mixpanel: any,
  elementId: string,
  options: TrackUpgradeOptions,
) {
  mixpanel?.track_links(`#${elementId}`, 'upgrade', options);
}

export function trackUpgrade(
  mixpanel: any,
  options: {
    upgrade_type: 'trial' | 'yearly' | 'monthly';
    source: 'web_subscribe_page' | 'unauthenticated_marketing_flow';
    current_track: number;
  },
) {
  mixpanel?.track('upgrade', options);
}

export function trackLinkToPlayStore(mixpanel: any) {
  mixpanel?.track('playstore_link_clicked');
}

export function trackLinkToAppStore(mixpanel: any) {
  mixpanel?.track('appstore_link_clicked');
}

export function trackInterestSelect(
  mixpanel: any,
  options: {
    interest:
      | 'web_apps'
      | 'data_science'
      | 'solving_problems'
      | 'games'
      | 'working_with_ai'
      | 'not_sure';
  },
) {
  mixpanel.people.set({ interest: options.interest });
  mixpanel?.track('set_interest', options);
}

export function trackResetChapter(mixpanel: any) {
  mixpanel?.track('reset_chapter');
}

export function trackUserMotive(
  mixpanel: any,
  options: { motive: 'work' | 'fun' | 'career' | 'other' },
) {
  mixpanel?.track('set_motive', options);
  mixpanel?.people.set({ motive: options.motive });
}

export function trackDidBecomeActive(mixpanel: any) {
  mixpanel?.track('did_become_active', {});
}

export function trackOpenFreeformProject(
  mixpanel: any,
  options: {
    project_id: string;
    number_of_files?: number;
    file_extensions?: string[];
    is_first_time_open: boolean;
    execution_environment: 'guillotine' | 'persistent_server';
    source_tutorial_id?: number;
  },
) {
  mixpanel?.track('open_freeform_project', options);
}

export type StarterTemplateType =
  | 'simple_website_v1'
  | 'react_hello_world_v1'
  | 'react_router_v1'
  | 'js_hello_world_v1'
  | 'python_hello_world_v1'
  | 'express_basic_endpoint_v1';

export function trackCreateFreeformProject(
  mixpanel: any,
  options: {
    project_id: string;
    source_tutorial_id?: number;
    starter_template?: StarterTemplateType;
    creation_source:
      | 'automatic_copy'
      | 'continue_project_flow_chapter_end'
      | 'continue_project_flow_section_details_page'
      | 'create_on_build_page'
      | 'duplicated_on_build_page';
  },
) {
  mixpanel?.track('create_freeform_project', options);
}

// track steps in the onboarding chat
export function trackChatOnboardingStep(
  mixpanel: any,
  options: {
    step: StateValueFrom<typeof introChatStateMachine>;
  },
) {
  mixpanel?.track('set_onboarding_step', options);
}

export function trackOnboardingStepV2(
  mixpanel: any,
  options: {
    step: string;
  },
) {
  mixpanel?.track('set_onboarding_step', options);
}

// track steps in the onboarding chat
export function trackChatOnboardingStepBack(
  mixpanel: any,
  options: {
    fromStep: string;
  },
) {
  mixpanel?.track('step_back_onboarding', options);
}

export function trackOnboardingStepExistingUsers(
  mixpanel: any,
  options: {
    step: ONBOARDING_STEPS_QUERY_PARAM;
    selectedTrackId: TrackId;
  },
) {
  mixpanel?.track('set_onboarding_step', options);
}

export function trackSpecialOfferStarted(
  mixpanel: any,
  options: {
    trial_length: number;
  },
) {
  mixpanel?.track('special_offer_started', options);
}

export function trackSwitchSound(mixpanel: any, options: { value: boolean }) {
  mixpanel?.track('switch_sound', options);
}

export type CodeRunOptions = {
  source: string;
  languages: CodingLanguage[];
  coding_time_seconds: number;
  trackId?: TrackId;
  tutorial_type?: string;
};

export function trackCodeRun(mixpanel: any, options: CodeRunOptions) {
  mixpanel?.track('code_run', options);
}

export type OpenEventRegistrationOptions = {
  event_name: string;
  event_url: string;
};

export function trackOpenEventRegistration(
  mixpanel: any,
  options: OpenEventRegistrationOptions,
) {
  mixpanel?.track('open_event_registration', options);
}

export interface SubmitAIChatRatingOptions {
  is_positive: boolean;
  custom_message: string;
  lesson_id?: number;
  track_id?: number;
}

export function trackTutorConversationRating(
  mixpanel: any,
  options: SubmitAIChatRatingOptions,
) {
  mixpanel?.track('submit_ai_chat_rating', options);
}

/** Web to app flow */

type WebToAppFlowOptions = {
  slug: string;
  // The value selected by the user in the onboarding step.
  // [null] if there is nothing to select on the step. (e.g. "slide" type steps)
  selectedValue: string | null;
};

export function trackWeb2AppFlowStep(
  mixpanel: any,
  options: WebToAppFlowOptions,
) {
  mixpanel?.track('web2app_step', options);
}

/* #################### GOOGLE ANALYTICS #################### */

export function trackSignupGTM(options: {
  source: 'email' | 'google' | 'apple';
}) {
  trackGTMEvent('signup', options);
}

export function trackShowPaywallDuringOnboardingGTM(options: {
  isTrial: boolean;
}) {
  trackGTMEvent('show_web_paywall_onboarding', options);
}

export function trackShowPaywallAfterOnboardingGTM(options: {
  isTrial: boolean;
}) {
  trackGTMEvent('show_web_paywall_non_onboarding', options);
}

export function trackVisitPurchaseSuccessful() {
  trackGTMEvent('visit_purchase_successful', {});
}

/**
 * Track events using GTM
 *
 * @param eventName
 * @param eventValue
 */
function trackGTMEvent(eventName: string, eventValue: any) {
  if (typeof window !== 'undefined' && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: eventName,
      eventValue,
    });
  }
}

/**
 * Track events using GTM
 *
 * @param eventName
 * @param eventValue
 */
function trackCustomerIOEvent(eventName: string, eventValues: any) {
  (window as any)._cio
    ? (window as any)._cio.track(eventName, eventValues)
    : null;
}

type TikTokEvent = {
  // NOTE: tiktok standard event
  eventName: 'CompleteRegistration';
  email: string | null | undefined;
};

/**
 * Track events using TikTok Pixel
 *
 * @param eventName
 * @param email
 */
export function trackTikTokEvent({ email, eventName }: TikTokEvent) {
  if (typeof window !== 'undefined' && (window as any).ttq) {
    //NOTE: combination of "manual and automatic matching" as recommended by tiktok docs https://business-api.tiktok.com/portal/docs?rid=5ipocbxyw8v&id=1739585700402178
    if (email) {
      (window as any).ttq.identify({ email });
    }
    (window as any).ttq.track(eventName);
  }
}
