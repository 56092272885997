import { User } from 'next-firebase-auth';
import { deleteActiveTrackAndSectionCookiesClientSide } from './cookie-utils';

/**
 * Utility function to parse JWT tokens without the need for an external lib.
 *
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 *
 * @param token encoded JWT token
 * @returns decoded JWT token
 */
export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString()
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

/**
 * Check if the current platform is a mac
 *
 * NOTE: Check is done using `navigator.userAgent` so this function
 * will only work client side.
 *
 * @returns true if the current platform is a mac
 */
export function isMacintosh() {
  if (typeof window !== 'undefined') {
    return navigator.userAgent.toLowerCase().indexOf('macintosh') > -1;
  } else {
    false;
  }
}

/**
 * Function to get BaseUrl depending on the env
 *
 * @param isPreview default true in dev env
 */
export function getBaseUrl(isPreview: boolean) {
  if (process.env.NODE_ENV === 'production') {
    // NOTE: we're in prod preview env -> use preview api
    if (isPreview) {
      return process.env.NEXT_PUBLIC_BASE_URL
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/preview`
        : 'https://api.getmimo.com/v1/preview';
    } else {
      return process.env.NEXT_PUBLIC_BASE_URL || 'https://api.getmimo.com/v1';
    }
  } else {
    // NOTE: we're not in prod env -> use preview api unless overridden
    if (isPreview) {
      return process.env.NEXT_PUBLIC_BASE_URL
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/preview`
        : 'https://api-testing.getmimo.com/v1/preview';
    } else {
      return (
        process.env.NEXT_PUBLIC_BASE_URL || 'https://api-testing.getmimo.com/v1'
      );
    }
  }
}

/**
 * UUID_V4 generator function
 *
 * @returns unique uuid
 */
export function UUID_V4() {
  if (typeof window === 'undefined') {
    // crypto is only available on the client
    return 'UUID hardcoded on server side';
  }
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
    /[018]/g,
    (c: any) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
  );
}

export function isJson(str: string) {
  let value = typeof str !== 'string' ? JSON.stringify(str) : str;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
}

/**
 * Logout and clear active track/section id cookies
 */
export function logoutAndClearCookies(user: User, mixpanel: any) {
  deleteActiveTrackAndSectionCookiesClientSide();
  mixpanel.reset();
  (window as any)._cio?.reset ? (window as any)._cio.reset() : null;
  user.signOut();
}

/**
 * Function to get the ordinal suffix of a number (e.g. 1st, 2nd, 3rd, 4th, etc.)
 *
 * @param i number
 * @returns ordinal suffix
 */
export function ordinalSuffixOf(i: number) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export type OperatingSystem = 'iOS' | 'Android' | 'Windows Phone' | 'unknown';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {OperatingSystem}
 */
export function getMobileOperatingSystem(userAgent: string): OperatingSystem {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return 'unknown';
}
