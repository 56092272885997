import { SSRPropsContext } from 'next-firebase-auth';
import { ParsedUrlQuery } from 'querystring';

export const COOKIE_SEPARATOR = '; ';
// active section & track cookie names
export const ACTIVE_TRACK_ID_COOKIE = 'mimo-web-active-track-id';
export const ACTIVE_SECTION_FOR_TRACK_PREFIX =
  'mimo-web-active-section-for-track-id';
export const COOKIE_PATH = '/';
export const MIMO_THEME_COOKIE = 'mimo-theme';
export const ADD_NOTIFICATION_COOKIE = 'mimo-add-notification-list';

/**
 * Shortcut for setting the cookie for the currently active track
 *
 * @param activeTrackId
 */
export function setCookieForActiveTrack(activeTrackId: number) {
  setCookie(ACTIVE_TRACK_ID_COOKIE, activeTrackId);
}

/**
 * Shortcut for setting the currently active section for a given track
 *
 * @param trackId
 * @param activeSectionIndex
 */
export function setCookieForActiveSectionForTrack(
  trackId: number,
  activeSectionIndex: number,
) {
  setCookie(
    `${ACTIVE_SECTION_FOR_TRACK_PREFIX}-${trackId}`,
    activeSectionIndex,
  );
}

/**
 * Shortcut for getting the cookie for the currently active track on the
 * client side
 *
 */
export function getClientSideCookieForActiveTrack() {
  return getCookieClientSide(ACTIVE_TRACK_ID_COOKIE);
}

/**
 * Shortcut for getting the currently active section for a given track on
 * the client side
 *
 * @param trackId
 * @returns
 */
export function getClientSideCookieForActiveSectionForTrack(trackId: number) {
  return getCookieClientSide(`${ACTIVE_SECTION_FOR_TRACK_PREFIX}-${trackId}`);
}

/**
 * Shortcut for getting the cookie for the currently active track on the
 * server side
 *
 */
export function getServerSideCookieForActiveTrack(
  ctx: SSRPropsContext<ParsedUrlQuery>,
) {
  return getCookieServerSide(ctx, ACTIVE_TRACK_ID_COOKIE);
}

/**
 * Shortcut for getting the currently active section for a given track on
 * the server side
 *
 * @param trackId
 * @returns
 */
export function getServerSideCookieForActiveSectionForTrack(
  ctx: SSRPropsContext<ParsedUrlQuery>,
  trackId: number,
) {
  return getCookieServerSide(
    ctx,
    `${ACTIVE_SECTION_FOR_TRACK_PREFIX}-${trackId}`,
  );
}

export function deleteActiveTrackAndSectionCookiesClientSide() {
  const cookies = getCookiesClientSide();
  if (cookies) {
    Object.keys(cookies).forEach((cookieName) => {
      if (
        cookieName === ACTIVE_TRACK_ID_COOKIE ||
        cookieName.startsWith(ACTIVE_SECTION_FOR_TRACK_PREFIX)
      ) {
        deleteCookieClientSide(cookieName);
      }
    });
  }
}

/**
 * Shortcut for getting the selected theme on the server side
 *
 * @returns
 */
export function getServerSideCookieForSelectedTheme(
  ctx: SSRPropsContext<ParsedUrlQuery>,
) {
  return getCookieServerSide(ctx, MIMO_THEME_COOKIE);
}

/**
 * Return a cookie to value map from a given cookie string
 *
 * @param cookieString
 * @returns
 */
export function getCookieMap(cookieString: string) {
  return cookieString.split(COOKIE_SEPARATOR).reduce(
    (prev, current) => {
      const [name, ...value] = current.split('=');
      if (name.trim() !== '') {
        prev[name] = value.join();
      }
      return prev;
    },
    {} as { [key: string]: string },
  );
}

// NOTE: non-exported helper functions

/**
 * Fuction to parse cookie string.
 *
 * Taken from https://github.com/jshttp/cookie
 *
 * @param str
 * @param options
 * @returns
 */
function parseCookieString(
  str: string,
  options?: any,
): {
  [key: string]: string;
} {
  if (typeof str !== 'string') {
    throw new TypeError('argument str must be a string');
  }

  const obj = {} as any;
  const opt = options || {};
  const dec = opt.decode || decodeURIComponent;

  let index = 0;
  while (index < str.length) {
    const eqIdx = str.indexOf('=', index);

    // no more cookie pairs
    if (eqIdx === -1) {
      break;
    }

    let endIdx = str.indexOf(';', index);

    if (endIdx === -1) {
      endIdx = str.length;
    } else if (endIdx < eqIdx) {
      // backtrack on prior semicolon
      index = str.lastIndexOf(';', eqIdx - 1) + 1;
      continue;
    }

    const key = str.slice(index, eqIdx).trim();

    // only assign once
    if (undefined === obj[key]) {
      let val = str.slice(eqIdx + 1, endIdx).trim();

      // quoted values
      if (val.charCodeAt(0) === 0x22) {
        val = val.slice(1, -1);
      }

      obj[key] = tryDecode(val, dec);
    }

    index = endIdx + 1;
  }

  return obj;
}

function tryDecode(str: any, decode: any) {
  try {
    return decode(str);
  } catch (e) {
    return str;
  }
}

/**
 * Sets cookie with given key and value.
 *
 * Options are samesite lax, secure and max age of 48 hours.
 *
 * @param key
 * @param value
 * @param options
 */
function setCookie(key: string, value: string | number, maxAge?: number) {
  document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
    value,
  )};samesite=lax;secure;max-age=${
    maxAge || 60 * 60 * 24 * 2
  };path=${COOKIE_PATH}`;
}

function getCookiesClientSide() {
  if (typeof window === 'undefined') {
    return;
  }

  const cookies = parseCookieString(document.cookie);
  return cookies;
}

function getCookieClientSide(key: string) {
  if (typeof window === 'undefined') {
    return;
  }

  const cookies = parseCookieString(document.cookie);
  return cookies[encodeURIComponent(key)];
}

function getCookieServerSide(
  ctx: SSRPropsContext<ParsedUrlQuery>,
  key: string,
) {
  return ctx.req.cookies[key];
}

export function deleteCookieClientSide(name: string) {
  if (typeof window === 'undefined') {
    return;
  }

  document.cookie =
    name + `=;path=${COOKIE_PATH};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
