import { ABTestToBucketMap } from '@customtypes/ab-tests';
import { ABTestBucket, ABTestNames } from '@utils/ab-testing/ab-tests';
import React, { useContext } from 'react';

export const ABTestsContext = React.createContext<
  ABTestToBucketMap | Record<string, never>
>({});

export const useABTest = (
  abTestName: ABTestNames,
): ABTestBucket | undefined => {
  const abTests = useContext(ABTestsContext);

  return abTests[abTestName];
};
