import { ABTestToBucketMap, ABTestsMap } from '@customtypes/ab-tests';
import { IncomingHttpHeaders } from 'http';
import { ABTestBucket, ABTestNames, AB_TESTS } from './ab-tests';
import { getCookieMap } from '@utils/cookie-utils';
import { RequestCookies } from 'next/dist/server/web/spec-extension/cookies';
import { decrypt, FlagOverridesType } from '@vercel/flags';

// taken from https://github.com/vercel/examples/blob/main/edge-functions/ab-testing-simple/lib/ab-testing.ts
export function chooseBucket(buckets: readonly ABTestBucket[]) {
  // Get a random number between 0 and 1
  let n = cryptoRandom() * 100;
  // Get the percentage of each bucket
  const percentage = 100 / buckets.length;
  // Loop through the buckets and see if the random number falls
  // within the range of the bucket
  return (
    buckets.find(() => {
      n -= percentage;
      return n <= 0;
    }) ?? buckets[0]
  );
}

function cryptoRandom() {
  return crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1);
}

/**
 * Get active AB tests from the list defined in ab-tests.ts
 *
 * @returns active ab tests
 */
export function getActiveABTests(): ABTestsMap {
  return Object.entries(AB_TESTS).reduce((prev, [key, value]) => {
    const startDate = new Date(value.startDate);
    const now = new Date();
    if (now >= startDate) {
      prev[key as ABTestNames] = value;
    }
    return prev;
  }, {} as ABTestsMap);
}

/**
 * Get the AB test bucket for a given AB test name from the incoming request cookies or from the list of outgoing cookies we use in the sec-cookie header in the response.
 *
 * This function should ONLY be used to get easy access to the AB test bucket in
 * middleware.
 *
 * @param requestCookies
 * @param abTestName
 */
export function getABTestBucketInMiddleware({
  incomingRequestCookies,
  outgoingSetCookieList,
  abTestName,
}: {
  incomingRequestCookies: RequestCookies;
  outgoingSetCookieList: {
    cookieName: ABTestNames;
    bucket: ABTestBucket;
    expires: Date;
  }[];
  abTestName: ABTestNames;
}) {
  return (
    incomingRequestCookies.get(abTestName)?.value ||
    outgoingSetCookieList.find((cookie) => cookie.cookieName === abTestName)
      ?.bucket ||
    undefined
  );
}

/**
 * Utility function to parse the AB test cookies from the request headers.
 * You can use this function directly if you want to get the AB test buckets
 * on the server side (e.g. in getServerSideProps).
 *
 * This function looks for cookies on the request or a custom cookie header
 * created by the middleware.
 *
 * @param requestHeaders request headers
 * @returns
 */
export async function parseABTestCookiesFromRequestHeader(
  requestHeaders: IncomingHttpHeaders,
) {
  const activeABTests = getActiveABTests();

  const req_custom_cookie_header = requestHeaders[
    CUSTOM_REQ_COOKIE_HEADER
  ] as string;

  const reqCustomCookieMap = req_custom_cookie_header
    ? getCookieMap(String(req_custom_cookie_header))
    : ({} as ABTestToBucketMap);

  const reqCookieMap = requestHeaders.cookie
    ? getCookieMap(requestHeaders.cookie)
    : ({} as ABTestToBucketMap);

  const abTests = Object.keys(activeABTests).reduce((prev, curr) => {
    const bucket =
      reqCustomCookieMap[curr as ABTestNames] ||
      reqCookieMap[curr as ABTestNames];

    if (bucket) {
      prev[curr as ABTestNames] = bucket as ABTestBucket;
    }

    return prev;
  }, {} as ABTestToBucketMap);

  const cookieMap = getCookieMap(requestHeaders.cookie || '');
  const overridesCookieValue = cookieMap['vercel-flag-overrides'];
  const overrides = overridesCookieValue
    ? await decrypt<FlagOverridesType>(overridesCookieValue)
    : null;

  return { ...abTests, ...overrides };
}

export const CUSTOM_REQ_COOKIE_HEADER = 'x-mimo-custom-set-cookie-header';
