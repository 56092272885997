import { ExecutableLessonFile } from '@customtypes/parsedLesson';
import React, { SetStateAction, useContext } from 'react';

export const UserReportLessonFilesContext = React.createContext({
  userReportLessonFiles: [] as ExecutableLessonFile[],
  setUserReportLessonFiles: (
    files: SetStateAction<ExecutableLessonFile[]>,
  ) => {},
});

/**
 * This context and hook is used to pass the user's code to the report modal.
 */
export const useUserReportLessonFiles = () => {
  return useContext(UserReportLessonFilesContext);
};
