// ./initAuth.js
import { initializeApp } from 'firebase/app';
import { getOnboardingCheckPageLink } from '@utils/link-utils-web';
import { IncomingMessage } from 'http';
import { User, init } from 'next-firebase-auth';

// set NEXT_PUBLIC_IMPERSONATE_USER_TOKEN in your local .env.local file
export const isImpersonatingToken =
  process.env.NEXT_PUBLIC_IMPERSONATE_USER_TOKEN;

export async function userToken(user: User) {
  return isImpersonatingToken || (await user.getIdToken());
}

// NOTE: taken from next-absolute-url
export function absoluteUrl(
  req?: IncomingMessage,
  localhostAddress = 'localhost:3000',
) {
  let host =
    (req?.headers ? req.headers.host : window.location.host) ||
    localhostAddress;
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:';

  if (
    req &&
    req.headers['x-forwarded-host'] &&
    typeof req.headers['x-forwarded-host'] === 'string'
  ) {
    host = req.headers['x-forwarded-host'];
  }

  if (
    req &&
    req.headers['x-forwarded-proto'] &&
    typeof req.headers['x-forwarded-proto'] === 'string'
  ) {
    protocol = `${req.headers['x-forwarded-proto']}:`;
  }

  return {
    protocol,
    host,
    origin: protocol + '//' + host,
  };
}

const allowedHosts = [
  'localhost:3000',
  'localhost:8888',
  'mimo-web-proxy.vercel.app',
  'getmimo.com',
  'mimo.org',
];

const initAuth = () => {
  const firebaseClientInitConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
    authDomain: 'mimo-auth-production.firebaseapp.com',
    databaseURL: 'https://mimo-auth-production.firebaseio.com',
    projectId: 'mimo-auth-production',
    storageBucket: 'mimo-auth-production.appspot.com',
    messagingSenderId: '384132561085',
    appId: '1:384132561085:web:f645db4fc4876d765d1fd4',
  };
  initializeApp(firebaseClientInitConfig);
  init({
    authPageURL: ({ ctx }) => {
      // NOTE: The ctx is the Next.js context value if server side, or undefined if client side.
      const isServerSide = typeof window === 'undefined';
      const origin = isServerSide
        ? absoluteUrl(ctx?.req).origin
        : window.location.origin;
      const destPath =
        isServerSide && ctx ? ctx.resolvedUrl : window.location.href;
      const destURL = new URL(destPath, origin) as any;
      return `/web/login?redirectUrl=${encodeURIComponent(destURL)}`;
    },
    appPageURL: ({ ctx }) => {
      // NOTE: The ctx is the Next.js context value if server side, or undefined if client side.
      const isServerSide = typeof window === 'undefined';
      const origin = isServerSide
        ? absoluteUrl(ctx?.req).origin
        : window.location.origin;
      const params = isServerSide
        ? new URL(ctx?.req?.url || '', origin).searchParams
        : new URLSearchParams(window.location.search);
      const redirectUrlParamVal = params.get('redirectUrl')
        ? decodeURIComponent(params.get('redirectUrl') || '')
        : undefined;

      // By default, go to the index page if the destination URL
      // is invalid or unspecified.
      let destURL = getOnboardingCheckPageLink();
      if (redirectUrlParamVal) {
        // Verify the redirect URL host is allowed.
        // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowed =
          allowedHosts.indexOf(new URL(redirectUrlParamVal).host) > -1;
        if (allowed) {
          destURL = new URL(redirectUrlParamVal).href;
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            `Redirect destination host must be one of ${allowedHosts.join(
              ', ',
            )}.`,
          );
        }
      }
      return destURL;
    },
    loginAPIEndpoint: '/api/login', // required
    logoutAPIEndpoint: '/api/logout', // required
    // firebaseAuthEmulatorHost: 'localhost:9099',
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: {
        projectId: process.env.FIREBASE_SERVICE_ACCOUNT_PROJECT_ID || '',
        clientEmail: process.env.FIREBASE_SERVICE_ACCOUNT_CLIENT_EMAIL || '',
        // The private key must not be accesssible on the client side.
        privateKey: process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY
          ? process.env.FIREBASE_SERVICE_ACCOUNT_PRIVATE_KEY.replace(
              /\\n/g,
              '\n',
            )
          : '',
      },
      databaseURL: 'https://mimo-auth-production.firebaseio.com',
    },
    firebaseClientInitConfig,
    cookies: {
      name: 'MimoWeb', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'lax',
      secure: process.env.NODE_ENV !== 'development', // set this to false in local (non-HTTPS) development
      signed: true,
    },
  });
};

export default initAuth;
