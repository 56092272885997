import { createContext, useContext } from 'react';

import { getBaseUrl } from '@utils/general-utils';
import { fetcherAndLog } from '@utils/fetcher';
import { User } from 'next-firebase-auth';
import { userToken } from './firebase/initAuth';

export const MixpanelContext = createContext<any>(null);

export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

// Send visit event to mimo backend
export async function trackVisitOnBackend(user: User) {
  const token = await userToken(user);
  if (!token) {
    return;
  }
  const url = `${getBaseUrl(false)}/user/visits`;
  const body = {
    appName: 'web',
  };
  await fetcherAndLog(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(body),
  });
}
