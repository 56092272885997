export function fetcherAndLog(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test'
  ) {
    console.log(input);
  }
  return fetch(input, init);
}
