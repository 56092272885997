import { ParsedUrlQuery } from 'querystring';
import { createContext, useContext } from 'react';

export interface RoutingHistoryEntry {
  pathname: string; // e.g. "/web/[trackId]/section/[sectionId]"
  asPath: string; // e.g "/web/50/section/0"
  query: ParsedUrlQuery; // e.g. { trackId: "50", sectionId: "0" }
}

/**
 * Context to store the routing history
 *
 * IMPORTANT: The first entry in the array is the page before the current page,
 * this means the further down the array, the further back in history it is.
 */
export const RoutingHistoryContext = createContext<{
  routingHistoryEntries: RoutingHistoryEntry[];
  setRoutingHistoryEntries: React.Dispatch<
    React.SetStateAction<RoutingHistoryEntry[]>
  >;
}>({
  routingHistoryEntries: [],
  setRoutingHistoryEntries: () => {},
});

export const useRoutingHistory = () => {
  const { routingHistoryEntries } = useContext(RoutingHistoryContext);

  return {
    routingHistoryEntries,
  };
};
